import { SystemLanguage } from '@zeiss/ng-vis-common/types';
const enGBExternal = {
  cim: {
    routes: {
      title: 'CIM',
      desc: 'Manage and organize the inventory',
      movements: {
        title: 'Material Movements',
        desc: 'Search and manage material movements'
      },
      inventory: {
        title: 'Material Inventory',
        desc: 'Search and filter the current inventory'
      },
      casepackMapping: {
        title: 'Casepack Mapping',
        desc: 'Search and inspect casepacks'
      },
      reservations: {
        title: 'Reservations',
        desc: 'Manage inventory reservations'
      },
      safetyStock: {
        title: 'Safety Stock',
        desc: 'Manage safety stock'
      },
      errorHandling: {
        title: 'Error Handling',
        desc: 'Manage occurred errors'
      },
      analytics: {
        title: 'Analyse Movements',
        desc: 'Analyse Materialmovements',
        movements: {
          title: 'Material Movements',
          desc: 'Material Movements & Material Details'
        }
      }
    }
  }
};
const deDEExternal = {
  cim: {
    routes: {
      title: 'CIM',
      desc: 'Verwaltung des Inventars',
      movements: {
        title: 'Material Movements',
        desc: 'Suche und Verwaltung von Materialbewegungen'
      },
      inventory: {
        title: 'Material Inventory',
        desc: 'Suche und Filtern des aktuellen Inventars'
      },
      casepackMapping: {
        title: 'Casepack Mapping',
        desc: 'Suche und Inspektion von Casepacks'
      },
      reservations: {
        title: 'Reservierungen',
        desc: 'Inventar Reservierungen verwalten'
      },
      safetyStock: {
        title: 'Sicherheitsbestand',
        desc: 'Sicherheitsbestand verwalten'
      },
      errorHandling: {
        title: 'Fehlerbehandlung',
        desc: 'Aufgetretene Fehler verwalten'
      },
      analytics: {
        title: 'Analysiere Movements',
        desc: 'Analysiere Materialbewegungen',
        movements: {
          title: 'Material Bewegungen',
          desc: 'Material Bewegungen & Material Details'
        }
      }
    }
  }
};
const esESExternal = {
  cim: {
    routes: {
      title: 'CIM',
      desc: 'Manage and organize the inventory',
      movements: {
        title: 'Material Movements',
        desc: 'Search and manage material movements'
      },
      inventory: {
        title: 'Material Inventory',
        desc: 'Search and filter the current inventory'
      },
      casepackMapping: {
        title: 'Casepack Mapping',
        desc: 'Search and inspect casepacks'
      },
      reservations: {
        title: 'Reservations',
        desc: 'Manage inventory reservations'
      },
      safetyStock: {
        title: 'Safety Stock',
        desc: 'Manage safety stock'
      },
      errorHandling: {
        title: 'Error Handling',
        desc: 'Manage occurred errors'
      },
      analytics: {
        title: 'Analysiere Movements',
        desc: 'Analysiere Materialbewegungen',
        movements: {
          title: 'Material Bewegungen',
          desc: 'Material Bewegungen & Material Details'
        }
      }
    }
  }
};

// Translations which can be used in VP outside library. Please do not export everything, this is exception, not a rule!
const CIM_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: enGBExternal,
  [SystemLanguage['de-DE']]: deDEExternal,
  [SystemLanguage['es-ES']]: esESExternal
};

/**
 * Generated bundle index. Do not edit.
 */

export { CIM_TRANSLATIONS };
