import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';
const CIM_PATHS = {
  _: 'cim',
  movements: 'movements',
  inventory: 'inventory',
  casepackMapping: 'casepack-mapping',
  reservations: 'reservations',
  safetyStock: 'safety-stock',
  errorHandling: 'error-handling',
  analytics: 'analytics'
};
const CIM_ROUTES = {
  path: CIM_PATHS._,
  canActivate: [AUTH_GUARD],
  data: {
    title: 'external.cim.routes.title',
    description: 'external.cim.routes.desc',
    icon: 'zui-icon-file-file-file',
    showInMenu: true,
    rule: vpRule({
      rule: RULE.access_cim,
      required: []
    }),
    routes: [{
      path: ''
    }, {
      path: CIM_PATHS.movements,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.cim.routes.movements.title',
        description: 'external.cim.routes.movements.desc',
        icon: 'zui-icon-size-distribution',
        showInMenu: true,
        rule: vpRule({
          rule: RULE.access_cim,
          required: ['movements']
        })
      }
    }, {
      path: CIM_PATHS.inventory,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.cim.routes.inventory.title',
        description: 'external.cim.routes.inventory.desc',
        icon: 'zui-icon-edf-edf-default',
        showInMenu: true,
        rule: vpRule({
          rule: RULE.access_cim,
          required: ['inventory']
        })
      }
    }, {
      path: CIM_PATHS.casepackMapping,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.cim.routes.casepackMapping.title',
        description: 'external.cim.routes.casepackMapping.desc',
        icon: 'zui-icon-volume',
        showInMenu: true,
        rule: vpRule({
          rule: RULE.access_cim,
          required: ['casepack-mapping']
        })
      }
    }, {
      path: CIM_PATHS.reservations,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.cim.routes.reservations.title',
        description: 'external.cim.routes.reservations.desc',
        icon: 'zui-icon-volume',
        showInMenu: false,
        rule: vpRule({
          rule: RULE.access_cim,
          required: ['reservations']
        })
      }
    }, {
      path: CIM_PATHS.safetyStock,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.cim.routes.safetyStock.title',
        description: 'external.cim.routes.safetyStock.desc',
        icon: 'zui-icon-volume',
        showInMenu: false,
        rule: vpRule({
          rule: RULE.access_cim,
          required: ['safety-stock']
        })
      }
    }, {
      path: CIM_PATHS.errorHandling,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.cim.routes.errorHandling.title',
        description: 'external.cim.routes.errorHandling.desc',
        icon: 'zui-icon-hard-drive-add-on-hard-drive-add-on-warning',
        showInMenu: true,
        rule: vpRule({
          rule: RULE.access_cim,
          required: ['error-handling']
        })
      }
    }, {
      path: CIM_PATHS.analytics,
      canActivate: [AUTH_GUARD],
      data: {
        title: 'external.cim.routes.analytics.title',
        description: 'external.cim.routes.analytics.desc',
        icon: 'zui-icon-oculign',
        showInMenu: true,
        rule: vpRule({
          rule: RULE.access_cim,
          required: ['analytics']
        })
      }
    }]
  }
};

/**
 * Generated bundle index. Do not edit.
 */

export { CIM_PATHS, CIM_ROUTES };
